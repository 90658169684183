import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ArrowDownSvgComponent, CloseModalSvgComponent } from '@uc/shared/svg';
import { DropdownDirective } from '@uc/utilities';

const menuItems = {
	content: 'Content/SEO',
	'client-success': 'Client Success',
	campaigns: 'Campaigns',
	analytics: 'Company Analytics',
};

@Component({
	selector: 'cms-navigation-mobile',
	templateUrl: './navigation-mobile.component.html',
	imports: [
		DropdownDirective,
		CloseModalSvgComponent,
		RouterLink,
		ArrowDownSvgComponent,
	],
})
export class NavigationMobileComponent implements OnInit {
	selected = '';
	@Output() closeSidebar = new EventEmitter<void>();

	constructor(private _location: Location) {}

	ngOnInit(): void {
		const path = this._location.path();
		const segment = path?.split('/').filter(Boolean)[0] as keyof typeof menuItems;
		if (segment) {
			this.selected = menuItems[segment];
		}
	}

	onSelected(value: string) {
		this.selected = value;
	}
}
