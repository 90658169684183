import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { ArrowDownSvgComponent } from '@uc/shared/svg';
import { DropdownDirective } from '@uc/utilities';

@Component({
	selector: 'cms-options-dropdown',
	imports: [ArrowDownSvgComponent, NgClass, DropdownDirective],
	templateUrl: './options-dropdown.component.html',
})
export class OptionsDropdownComponent {
	label = input.required<string>();
}
