import { Component, input } from '@angular/core';

@Component({
	selector: 'cms-header',
	imports: [],
	templateUrl: './header.component.html',
})
export class HeaderComponent {
	titleText = input.required<string>();
	description = input.required<string>();
}
